<template></template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  watch,
  onUnmounted,
  ref
} from 'vue';

import { shippingMapClusterShops } from '$modules/shipping/helpers/shipping-map-cluster';

export default defineComponent({
  name: 'ShippingMapShops',
  components: {
  },
  props: {
    map: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      default: () => ([])
    },
    icon: {
      type: String,
      required: true
    },
    selected: {
      type: Object,
      default: null
    }
  },
  setup (props, { emit }) {
    const mounted = ref(false)
    const selected = computed(() => props.selected)

    const data = computed(() => props.list)

    const handleUpdate = () => {
      const list = data.value.filter(i => i.id !== props.selected?.id);

      const geojsonData = shippingMapClusterShops(list)
      const selectedData = shippingMapClusterShops(props.selected ? [props.selected] : [])

      props.map.getSource('shops').setData(geojsonData);
      props.map.getSource('shops-selected').setData(selectedData);
    }

    onMounted(() => {
      props.map.loadImage(props.icon)
        .then((image) => props.map.addImage('shops-icon', image.data))

      props.map.addSource('shops', {
        type: 'geojson',
        data: shippingMapClusterShops([]),
        cluster: false,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      props.map.addSource('shops-selected', {
        type: 'geojson',
        data: shippingMapClusterShops([]),
        cluster: false,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      props.map.addLayer({
        'id': 'shops',
        'type': 'symbol',
        'source': 'shops',
        'layout': {
          'icon-image': 'shops-icon',
          'icon-overlap': 'always',
          'icon-size': 0.3,
          'icon-anchor': 'bottom'
        }
      });

      props.map.addLayer({
        'id': 'shops-selected',
        'type': 'symbol',
        'source': 'shops-selected',
        'layout': {
          'icon-image': 'shops-icon',
          'icon-overlap': 'always',
          'icon-size': 0.4,
          'icon-anchor': 'bottom'
        }
      });

      props.map.on('click', 'shops', (e) => {
        emit('select', {
          id: e.features[0].properties?.id
        })
      });

      handleUpdate()

      mounted.value = true
    })

    watch(
      selected,
      (newVal, oldVal) => {
        if (!newVal || newVal?.id === oldVal?.id || !mounted.value) return

        handleUpdate()
      }
    );

    watch(
      data,
      () => {
        handleUpdate()
      }
    );

    onUnmounted(() => {
      props.map.removeImage('shops-icon');
      props.map.removeLayer('shops');
      props.map.removeLayer('shops-selected');
      props.map.removeSource('shops');
      props.map.removeSource('shops-selected');

      props.map.off()
    })
  }
});
</script>

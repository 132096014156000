<template>
  <div class="sw-map">
    <ShippingMap
      ref="map"
      name="widget"
      class="sw-map__map"
      :config="mapOptions"
      :map-center="mapCenter"
      :scroll-ctrl="true"
      :map-zoom="mapZoom"
      @ready="onMapReady"
      @update:zoom="setZoom"
      @update:center="setCenter"
    />

    <ShippingMapShops
      v-if="map && mapReady"
      :map="map"
      :list="shops"
      :icon="vector.shopIcon"
      :selected="selected"
      @select="selectShop"
    />

    <div class="sw-map__zoom">
      <button
        class="sw-map__zoom-in"
        :disabled="zoom === mapOptions.maxZoom"
        @click="zoomIn"
      ></button>
      <button
        class="sw-map__zoom-out"
        :disabled="zoom === mapOptions.minZoom"
        @click="zoomOut"
      ></button>
    </div>
  </div>
</template>

<script>
import ShippingMap from '$modules/shipping/components/map/shipping-map.vue';
import ShippingWidgetMixin from '$modules/shipping/mixins/shipping-widget-mixin';
import ShippingMapShops from '$modules/shipping/components/map/shipping-map-shops.vue';

export default {
  name: 'ShippingAreaMap',
  components: {
    ShippingMapShops,
    ShippingMap
  },
  mixins: [ShippingWidgetMixin]
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import "~theme/css/px2rem";
@import '~theme/css/fonts';

.sw-map {
  height: 35.5625rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;

  &__map {
    flex: 1;
  }

  .sw-map__zoom {
    border: none;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    position: absolute;
    z-index: 9990;
    right: 24px;
    bottom: 24px;
    display: flex;
    flex-direction: column;
  }

  ::v-deep {
    .sw-map__zoom-in,
    .sw-map__zoom-out,
    .sw-map__locate-button {
      transition: opacity 200ms;
      width: 48px;
      height: 48px;
      border: none;
      position: relative;
      content: ' ';
      color: transparent;
      background: var(--white);

      &:disabled {
        opacity: .5;
        pointer-events: none;
        background: none;
      }

      &:before {
        transition: color 200ms;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover:before {
        color: var(--orange)
      }
    }

    .sw-map__zoom-in {
      @include font-icon(var(--icon-plus));

      &:before {
        color: var(--black);
        font-size: 15px;
      }
    }

    .sw-map__zoom-out {
      @include font-icon(var(--icon-minus));

      &:before {
        color: var(--black);
        font-size: 15px;
      }
    }
  }
}
</style>

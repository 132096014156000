import { LngLat } from 'maplibre-gl/dist/maplibre-gl'
import debounce from 'lodash/debounce';

const DEFAULT_ZOOM = 11
const DEFAULT_MAP_ZOOM = 5
const DEFAULT_CENTER = { lat: 50.450001, lng: 30.523333 }

export default {
  name: 'ShippingWidgetMixin',
  props: {
    city: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Object,
      default: () => ({})
    },
    shops: {
      type: Array,
      default: () => []
    },
    pageType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      map: null,
      mapReady: false,
      mapZoom: DEFAULT_MAP_ZOOM,
      mapCenter: new LngLat(DEFAULT_CENTER.lng, DEFAULT_CENTER.lat),
      zoom: DEFAULT_MAP_ZOOM,
      center: new LngLat(DEFAULT_CENTER.lng, DEFAULT_CENTER.lat),
      mapOptions: {
        preferCanvas: true,
        scrollWheelZoom: 'center',
        doubleClickZoom: 'center',
        touchZoom: 'center',
        minZoom: 2,
        maxZoom: 18,
        boxZoom: false,
        zoomControl: false,
        attributionControl: false,
        zoomSnap: 0.5
      },
      vector: {
        shopIcon: '/assets/map-pin-varus.webp'
      },
      buffer: null
      // gestureHandling: {
      //   text: {
      //     touch: this.$t('Two finger move map'),
      //     scroll: isMac ? this.$t('Mac scroll map') : this.$t('Text scroll map'),
      //     scrollMac: isMac ? this.$t('Mac scroll map') : this.$t('Text scroll map')
      //   }
      // }
    }
  },
  watch: {
    city: {
      immediate: true,
      handler: function (newVal) {
        if (!newVal?.id) return
        this.setCenterAndZoom(newVal?.coordinates?.lat, newVal?.coordinates?.lng, DEFAULT_ZOOM)
      }
    }
  },
  beforeDestroy () {
    this.mapEventsStop()
  },
  computed: {
    ready () {
      return this.mapReady
    }
  },
  methods: {
    setCenterAndZoom (lat, lng, zoom, priority) {
      if (!this.map?.jumpTo || !this.ready) {
        this.buffer = this.buffer?.priority > priority
          ? this.buffer
          : {
            lat: lat,
            lng: lng,
            zoom: zoom || this.buffer?.zoom || null,
            priority: priority || 0
          }

        this.mapCenter = new LngLat(this.buffer.lng, this.buffer.lat)
        this.mapZoom = this.buffer.zoom

        return
      }

      const currentZoom = zoom || this.zoom
      const currentCenter = new LngLat(+lng, +lat) || this.center

      this.zoom = currentZoom

      if (!this.ready) {
        this.buffer = this.buffer?.priority > priority
          ? this.buffer
          : {
            lat: lat,
            lng: lng,
            zoom: currentZoom || this.buffer?.zoom || null,
            priority: priority || 0
          }

        this.mapCenter = new LngLat(this.buffer.lng, this.buffer.lat)
        this.mapZoom = this.buffer.zoom

        return
      }

      this.setView(currentCenter, currentZoom)
    },
    setView: debounce(function (currentCenter, currentZoom) {
      this.map.easeTo({ center: currentCenter, zoom: currentZoom })
    }, 300),
    onMapReady () {
      this.map = this.$refs.map.mapObject
      this.mapReady = true
    },
    setCenter (center) {
      this.center = center
    },
    setZoom (zoom) {
      this.zoom = zoom
    },
    zoomIn () {
      return this.map?.zoomIn()
    },
    zoomOut () {
      return this.map?.zoomOut()
    },
    selectShop (shop) {
      const item = this.shops.find((s) => s.id === shop.id)

      if (!item) return

      this.$emit('open-info', item)
      this.setCenterAndZoom(+item?.lat, +item?.long)
    }
  }
}
